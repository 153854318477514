import 'bootstrap/js/dist/collapse.js';
import PerfectScrollbar from 'perfect-scrollbar/dist/perfect-scrollbar.common.js';
import lodash from 'lodash';
import QueryStringHandler from 'QueryStringHandler';
import AjaxHandler from 'AjaxHandler';
import CityCountry from 'CityCountry';
import HereMaps from '../../../../../../Project/Common/code/src/scripts/maps/HereMaps.js';

/**
 * Dealer locator functionality
 *
 * @memberOf module:feature/Dealer
 * @requires bootstrap/collapse
 * @requires lodash
 * @requires perfect-scrollbar
 * @requires module:project/Common.QueryStringHandler
 * @requires module:project/Common.CityCountry
 * @requires module:project/Maps.HereMaps
 * @version 1.0.0
 * @author Rocco Janse <rocco.janse@valtech.nl>
 */
class DealerLocator {
    /**
     * Initializes all config and vars.
     * @param {jQueryElement} $element DOM Element to be upgraded.
     */
    constructor($element) {
        // elements
        this.$element = $element;
        this.$mainPanel = this.$element.find('.col-search');
        this.$searchPanel = this.$element.find('.search');
        this.$resultsPanel = this.$element.find('.results');
        this.$flyoutContainers = this.$element.find('.flyout-container');
        this.$resultsFilterCollapseTrigger = this.$element.find('.search__results-filter .collapse-trigger');
        this.$resultsFilterCollapse = this.$element.find('.search__results-filter .collapse');
        this.$dealerList = this.$element.find('.dealerlist');
        this.$dealerListScrollbar = null;
        this.$mapElement = this.$element.find('.map');

        // config
        this.locale = this.$element.attr('data-map-locale');
        this.selectedCountryCode = this.$element.attr('data-map-country');
        this.selectedCity = false;
        this.defaultRange = this.$element.attr('data-map-range');

        // buttons
        this.buttons = {
            USEMYLOCATION: this.$element.find('.form-check.uselocation .form-check-label'),
            DEALERTYPESSEARCH: this.$element.find('.dealertypes--search .form-check.dealertype input'),
            DEALERTYPESRESULTS: this.$element.find('.results .form-check.dealertype input'),
            SEARCH: this.$element.find('.btn.btn--dealer-search'),
            ALLDEALERS: this.$element.find('.btn.btn--alldealers'),
            LATLNGSEARCHBTN: this.$element.find('.btn.btn--dealer-latlng'),
        };

        // controls
        this.controls = {
            RANGE: this.$element.find('.form-control.form-control--range'),
            COUNTRY: this.$element.find('.form-control.form-control--country'),
            CITY: this.$element.find('.form-control.form-control--city'),
            ZIPCODE: this.$element.find('.form-control.form-control--dl-zipcode'),
            LAT: this.$element.find('.form-control.form-control--latitude'),
            LNG: this.$element.find('.form-control.form-control--longitude'),
        };

        // templates
        this.templates = {
            RESULTSTEXT: this.$element.find('.search__results-title').html(),
            DEALERDETAILTYPES: this.$element
                .find('.dealerlist ul li')
                .first()
                .find('.flyout')
                .find('.dealertypes__type')
                .detach(),
            DEALERDETAIL: this.$element.find('.dealerlist ul li').first().find('.flyout__content').detach(),
            DEALERLISTITEM: this.$element.find('.dealerlist ul li').first().detach(),
        };

        // selections
        this.useLocation = false;
        this.country = false;
        this.city = false;
        this.range = 0;
        this.rangeCircle = null;
        this.zipCode = null;
        this.defaultFilters = this.$element.attr('data-prefilters').split(',') || [];
        this.userFilters = this.$element.attr('data-prefilters').split(',') || [];
        this.openedDetailCode = false;
        this.lat = 0;
        this.lng = 0;
        this.latlngAreValid = false;

        // hide use my location button if geolocation is not available
        if (!navigator.geolocation) {
            this.buttons.USEMYLOCATION.hide();
        }

        // get qsparams
        this.qsParams = QueryStringHandler.getParams();
        if (this.qsParams && this.qsParams.country && this.qsParams.code) {
            this.selectedCountryCode = this.qsParams.code;
        }
        if (this.qsParams && this.qsParams.city) {
            this.selectedCity = this.qsParams.city;
        }

        // maps settings
        const mapLanguage = this.$element.attr('data-map-language').toLowerCase();
        const mapLocale = this.locale.split('-');
        const lang = mapLocale[0];
        const region = mapLocale[1];

        // FireFox/Edge quick Fix: map container needs to have a height (which isnt 100%)
        this.$mapElement.height(this.$element.height());

        this.map = new HereMaps(this.$element, {
            appLanguage: lang,
            appRegion: region,
            appTileLanguage: mapLanguage, // see https://developer.here.com/documentation/map-tile/topics/resource-base-maptile.html#resource-base-maptile__param-lg
            $mapContainer: this.$mapElement,
            map: {
                center: { lat: 51.43328, lng: 5.50428 },
                zoom: 8,
                events: {
                    onReady: () => {
                        this.onReady();
                    },
                },
            },
        });

        // init city/country dependency class
        this.cityCountry = new CityCountry(this.$element, {
            language: this.locale,
            selectedCountryCode: this.selectedCountryCode,
            selectedCity: this.selectedCity,
        });

        // listen for country/city changes
        this.cityCountry.addListener('countrychanged', (selectedCountryCode, selectedCountryName) => {
            this.updateCountry(
                this.cityCountry.getIso2CountryCode(selectedCountryCode),
                this.cityCountry.getIso3CountryCode(selectedCountryCode),
                selectedCountryName,
                true,
                false
            );
        });
        this.cityCountry.addListener('citychanged', (selectedCity) => {
            this.updateCity(selectedCity);
        });

        // set range to default
        //this.controls.RANGE.val(this.range);

        // group to store markers
        this.markersGroup = null;

        // marker-, cluster- and locaction icons
        this.map.markerSvgMarkup = this.getMarkerSvgMarkup();
        this.map.clusterSvgMarkup = this.getClusterSvgMarkup();
        this.map.myLocationSvgMarkup = this.getMyLocationSvgMarkup();

        // settings
        this.useLocationMarker = null;
        this.latlngMarker = null;

        // ajax handler
        this.ajax = new AjaxHandler();

        // loaders
        this.$searchLoader = this.$searchPanel.find('#searchloader');
        this.$dealerListLoader = this.$dealerList.find('#dealerlistloader');
        this.$flyoutLoader = this.$element.find('.loader--flyout');
        this.$mapLoader = this.$element.find('#maploader');
    }

    /**
     * Initializes Here maps API.
     */
    init() {
        // init coutry/city selectboxes already if no map
        if (viewport.is('<md')) {
            this.cityCountry.init();
        }

        // // initially show
        // this.handleSearchButtonClick(null, false);

        // load api and show here map when on desktop view initially
        this.onResize();

        // search control event handlers
        this.controls.RANGE.on('change.dealerlocator', this.handleChangeRange.bind(this));
        this.controls.ZIPCODE.on('input.dealerlocator', lodash.debounce(this.updateZipCode.bind(this), 750));
        this.controls.LAT.on('input.dealerlocator', this.handleChangeLatLng.bind(this));
        this.controls.LNG.on('input.dealerlocator', this.handleChangeLatLng.bind(this));

        // button event handlers
        this.buttons.USEMYLOCATION.on('click.dealerlocator', this.handleUseLocation.bind(this));
        this.buttons.LATLNGSEARCHBTN.on('click.dealerlocator', this.handleGetLatLng.bind(this));

        // dealer type filters
        this.buttons.DEALERTYPESSEARCH.on('click.dealerlocator', this.handleSearchFiltersClick.bind(this));
        this.buttons.DEALERTYPESRESULTS.on('click.dealerlocator', this.handleResultsFiltersClick.bind(this));

        // search button
        this.buttons.SEARCH.on('click.dealerlocator', this.handleSearchButtonClick.bind(this));

        // filter collapse on results page
        this.$resultsFilterCollapseTrigger.on('click.dealerlocator', this.handleResultsFilterToggle.bind(this));

        // dealer list scrollbar
        if (viewport.is('>=md')) {
            this.initDealerScrollbar();
        }

        // hide search form loader
        this.$searchLoader.hide();

        // window event handlers
        $(window).on('resize.dealerlocator', lodash.debounce(this.onResize.bind(this), 200));
    }

    /**
     * When map is ready and loaded.
     */
    onReady() {
        // init coutry/city selectboxes and update map
        this.cityCountry.init().then(() => {
            // show dealers initially
            setTimeout(() => {
                this.handleSearchButtonClick(null, false);
            }, 200);
            //this.$mapLoader.fadeOut(200);
        });
    }

    /**
     * Show found dealers in the dealerlist and/or map.
     * @param {jQuery} [e] jQuery event, optional.
     * @param {boolean} [showDealerList = true] Optional, if set to false, dealer list won't show.
     */
    handleSearchButtonClick(e, showDealerList = true) {
        if (typeof e !== 'undefined' && e !== null) {
            e.preventDefault();
        }

        this.$mapLoader.show();

        if (showDealerList === true) {
            // show loader first
            this.$searchLoader.fadeIn(200);
            this.$dealerListLoader.fadeIn(200);
        }

        // create main location based POST data
        let postData = {};

        if (this.useLocation) {
            postData.latitude = this.useLocation.lat;
            postData.longitude = this.useLocation.lng;
            // use my location set, send lat/lng
            postData.range = this.range === 0 ? this.defaultRange : this.range;
        } else if (this.latlngAreValid) {
            postData.latitude = this.lat;
            postData.longitude = this.lng;
            postData.range = this.range === 0 ? this.defaultRange : this.range;
        } else {
            if (this.country) {
                if (this.zipCode && this.zipCode.isValid) {
                    // country and zip code selected
                    postData.latitude = this.zipCode.center.lat;
                    postData.longitude = this.zipCode.center.lng;
                    postData.range = this.range === 0 ? this.defaultRange : this.range;
                } else if (this.city) {
                    // country and city selected
                    postData.latitude = this.city.center.lat;
                    postData.longitude = this.city.center.lng;
                    postData.range = this.range === 0 ? this.defaultRange : this.range;
                } else {
                    // just country selected
                    if (this.range) {
                        // country and range selected
                        const currentCenter = this.map.getCalculatedCenter();
                        postData.latitude = currentCenter.lat;
                        postData.longitude = currentCenter.lng;
                        postData.range = this.range;
                    } else {
                        // country without range selected, send countrycode (iso2)
                        postData.countryCode = this.country.iso2;
                    }
                }
            } else if (this.range) {
                // country not selected
                const currentCenter = this.map.getCalculatedCenter();
                postData.latitude = currentCenter.lat;
                postData.longitude = currentCenter.lng;
                postData.range = this.range;
            }
        }

        // add additional filters
        let filterData = this.userFilters.join(','); // lodash.union(this.defaultFilters, this.userFilters).join(',');
        postData.dealerTypes = filterData;

        this.ajax
            .request({
                url: `/${this.locale}/api/feature/dealer/getdealers`,
                data: JSON.stringify(postData),
            })
            .then((response) => {
                if (response.Success === true) {
                    const dealerData = response.Dealers;

                    // get range from response
                    if (response.Range !== 0) {
                        this.range = response.Range;
                        this.controls.RANGE.val(this.range);
                    }

                    if (showDealerList === true) {
                        // render results
                        this.renderResultsText(dealerData.length);

                        this.showResultsPanel();
                        this.$searchLoader.fadeOut(200, () => {
                            this.parseDealerData(dealerData).then(() => {
                                this.$mapLoader.fadeOut(200);
                                this.$dealerListLoader.fadeOut(200);
                                this.$dealerListScrollbar.update();
                            });
                        });
                    } else {
                        // initial call shows the dealers on the map, but doesn't show the list
                        this.parseDealerData(dealerData, false).then(() => {
                            this.$mapLoader.fadeOut(200);
                        });
                    }
                } else {
                    if (showDealerList === true) {
                        // render error
                        this.renderResultsText();
                        this.showResultsPanel();
                        this.$searchLoader.fadeOut(200, () => {
                            this.parseDealerData(response.Reason).then(() => {
                                this.$mapLoader.fadeOut(200);
                                this.$dealerListLoader.fadeOut(200);
                            });
                        });
                    }
                }
            });
    }

    /**
     * Adds checked filter to userFilters array, removes unchecked ones.
     * @param {jQueryEvent} e jQuery Event.
     */
    handleSearchFiltersClick(e) {
        //e.preventDefault();
        const $currentFilter = $(e.currentTarget);
        const currentId = $currentFilter.attr('id');
        const resInputId = `res-${currentId}`.replace(/:/g, '\\:');
        const currentIsChecked = $currentFilter.prop('checked');

        // keep selected filter inline
        this.$element.find(`#${resInputId}`).prop('checked', currentIsChecked);

        // add remove filter
        if (currentIsChecked) {
            this.addFilter($currentFilter.val());
        } else {
            this.removeFilter($currentFilter.val());
        }
    }

    /**
     * Adds checked filter to userFilters array, removes unchecked ones.
     * @param {jQueryEvent} e jQuery Event.
     */
    handleResultsFiltersClick(e) {
        //e.preventDefault();
        const $currentFilter = $(e.currentTarget);
        const currentId = $currentFilter.attr('id');
        const searchInputId = `${currentId}`.replace(/res-/g, '').replace(/:/g, '\\:');
        const currentIsChecked = $currentFilter.prop('checked');

        // keep selected filter inline
        this.$element.find(`#${searchInputId}`).prop('checked', currentIsChecked);

        // add remove filter
        if (currentIsChecked) {
            this.addFilter($currentFilter.val());
        } else {
            this.removeFilter($currentFilter.val());
        }

        // auto update dealerlist with new filtered results
        this.handleSearchButtonClick();
    }

    /**
     * Adds a filter value to the user filters array, if not already existent.
     * @param {string} value Value of the filter to add.
     */
    addFilter(value) {
        if (this.userFilters.indexOf(value) === -1) {
            this.userFilters.push(value);
        }
    }

    /**
     * Removes a filter value to the user filters array.
     * @param {string} value Value of the filter to add.
     */
    removeFilter(value) {
        const index = this.userFilters.indexOf(value);
        if (index > -1) {
            this.userFilters.splice(index, 1);
        }
    }

    /**
     * Results filter collapse.
     * @param {jQueryEvent} e jQuery Event.
     */
    handleResultsFilterToggle(e) {
        e.preventDefault();
        this.$resultsFilterCollapse.on('hidden.bs.collapse', () => {
            this.$dealerListScrollbar.update();
        });
        this.$resultsFilterCollapse.on('shown.bs.collapse', () => {
            this.$dealerListScrollbar.update();
        });
        if (this.$resultsFilterCollapse.hasClass('show')) {
            this.$resultsFilterCollapseTrigger.removeClass('is-active');
            // dunno why, but we need to re-init object as jquery object
            $(this.$resultsFilterCollapse).collapse('hide');

            this.resetLatLng();
        } else {
            this.$resultsFilterCollapseTrigger.addClass('is-active');
            // dunno why, but we need to re-init object as jquery object
            $(this.$resultsFilterCollapse).collapse('show');
        }
    }

    /**
     * Renders result string based on search settings.
     * @param {number} totalResults
     */
    renderResultsText(totalResults = 0) {
        const $element = this.$element.find('.search__results-title');
        let text = this.templates.RESULTSTEXT;
        text = text.replace(/%%TOTALRESULTS%%/g, totalResults);
        if (this.useLocation) {
            text = text.replace(/%%COUNTRY%%/g, '');
            text = text.replace(/%%CITY%%/g, '');
            text = text.replace(/%%ZIPCODE%%/g, '');
            text = text.replace(
                /%%LOCATION%%/g,
                `<span class="underline">${$element.attr('data-text-location')}</span>`
            );
        } else if (this.latlngAreValid) {
            text = text.replace(/%%COUNTRY%%/g, '');
            text = text.replace(/%%CITY%%/g, '');
            text = text.replace(/%%ZIPCODE%%/g, '');
            text = text.replace(
                /%%LOCATION%%/g,
                `<span class="underline">${this.lat}</span><span class="underline">${this.lng}</span>`
            );
        } else {
            const country = this.country.name
                ? `<span class="underline">${this.country.name}</span>`
                : `<span class="underline">${$('#country option:selected').text()}</span>`;
            const city = this.city.name ? `<span class="underline">${this.city.name}</span>` : false;
            const zipCode =
                this.zipCode && this.zipCode.isValid ? `<span class="underline">${this.zipCode.value}</span>` : false;
            text = text.replace(/%%COUNTRY%%/g, country || '');
            text = text.replace(/%%CITY%%/g, city || '');
            text = text.replace(/%%ZIPCODE%%/g, zipCode || '');
            text = text.replace(/%%LOCATION%%/g, '');
        }
        $element.html(text);

        // (re-)attach close event
        $element
            .find('.icon--close')
            .off('click.dealerlocator')
            .on('click.dealerlocator', (e) => {
                e.preventDefault();

                // reset city
                this.controls.CITY.val('');
                this.updateCity(false);

                // reset range
                this.controls.RANGE.val('');
                this.resetRange();

                this.resetZipCode();
                this.resetLatLng();

                // get dealers again
                this.handleSearchButtonClick(null, false);

                this.showSearchPanel();
            });
    }

    /**
     * Parses dealerlist items. And adds markers to map, dealerlist entries etc.
     * @param {array|string} data Array to render or (error) string to render.
     * @param {boolean} [markerClick = true] Optional flag to enable marker clicks, true by default.
     * @returns Promise
     */
    parseDealerData(data, markerClick = true) {
        return new Promise((resolve) => {
            let promises = [];
            let dealerListHtml = [];

            // clustering datapoints
            let datapoints = [];

            if (this.map.initialized) {
                if (!this.markersGroup) {
                    // create marker group to store markers and get bounding box
                    this.markersGroup = this.map.createMapGroup();
                    // add group to map
                    //this.map.addObject(this.markersGroup);
                } else {
                    // remove all markers from group
                    this.markersGroup.removeAll();
                }

                if (this.clusterLayer) {
                    this.map.removeLayer(this.clusterLayer);
                }
            }

            if (typeof data !== 'string') {
                // render list
                data.forEach((dealer, index) => {
                    const promise = new Promise((resolve) => {
                        const markerIndex = index + 1;

                        // build list
                        dealerListHtml.push(this.renderDealerlistItem(markerIndex, dealer));

                        // add markers to markergroup; thus on the map
                        if (this.map.initialized) {
                            // add marker to group for bbox
                            this.markersGroup.addObject(
                                this.map.createMarker({ lat: dealer.Latitude, lng: dealer.Longitude }, markerIndex)
                            );

                            // add marker (datapoint) to object
                            datapoints.push(
                                this.map.createClusterDataPoint(dealer.Latitude, dealer.Longitude, null, {
                                    markerIndex: markerIndex,
                                    dealerCode: dealer.DealerCode,
                                })
                            );
                            resolve();
                        } else {
                            resolve();
                        }
                    });
                    promises.push(promise);
                });
            } else {
                // render error
                const promise = new Promise((resolve) => {
                    dealerListHtml.push(data);
                    this.$dealerList
                        .find('ul')
                        .html(`<li class="error"><div class="dealerlist__row"><p>${data}</p></div></li>`);
                    resolve();
                });
                promises.push(promise);
            }

            Promise.all(promises).then(() => {
                // remove listeners from memory of current dealerlist
                this.$dealerList.find('ul li').off('click.dealerlocator');

                // if no error occured
                if (typeof data !== 'string') {
                    // create new dealerlist
                    this.$dealerList.find('ul').html(dealerListHtml.join(''));

                    // (re-)add dealerlist event listeners
                    this.$dealerList
                        .find('ul li:not(ul li.error)')
                        .on('click.dealerlocator', this.showDealerDetails.bind(this));

                    // update valtagmanager
                    if (window.digitalData) {
                        window.digitalData.update();
                    }

                    if (this.map.initialized) {
                        // clustering
                        const clusterProvider = this.map.createClusterProvider(datapoints);
                        if (markerClick === true) {
                            clusterProvider.addEventListener('tap', this.onMarkerClick.bind(this));
                        }
                        this.clusterLayer = this.map.createLayer(clusterProvider);

                        this.map.addLayer(this.clusterLayer);

                        // we need a 500ms timeout otherwise the map wont update
                        setTimeout(() => {
                            let maxZoom = false;
                            const bounds = this.markersGroup.getBoundingBox();

                            if (!bounds) return resolve();

                            // if there are five or less results, maxZoom is 10
                            if (data.length <= 5) {
                                maxZoom = 10;
                            }

                            // update map
                            this.updateMap(
                                {
                                    topLeft: {
                                        lat: bounds.getTopLeft().lat,
                                        lng: bounds.getTopLeft().lng,
                                    },
                                    bottomRight: {
                                        lat: bounds.getBottomRight().lat,
                                        lng: bounds.getBottomRight().lng,
                                    },
                                },
                                maxZoom
                            );

                            resolve();
                        }, 500);
                    } else {
                        resolve();
                    }
                } else {
                    // render error
                    this.$dealerList
                        .find('ul')
                        .html(
                            `<li class="error"><div class="dealerlist__row"><p>${dealerListHtml.join(
                                ''
                            )}</p></div></li>`
                        );
                    resolve();
                }
            });
        });
    }

    /**
     * Handles marker clicks, shows marker on map center and selectes selected item in dealerlist.
     * @param {event} e Event.
     */
    onMarkerClick(e) {
        const position = e.target.getPosition();
        const data = e.target.getData();

        if (typeof data !== 'undefined') {
            this.map.setCenter(position, true, false);

            const $currentListItem = this.$dealerList.find(`[data-index="${data.markerIndex}"]`);
            this.$dealerList.animate({
                scrollTop: this.$dealerList.scrollTop() + $currentListItem.position().top,
            });
            this.showDealerDetails({ currentTarget: $currentListItem[0] });
        }
    }

    /**
     * Renders dealer list items.
     * @param {number} index Index of current item.
     * @param {object} dealerData Data to render.
     * @return {string} Dealer list item html.
     */
    renderDealerlistItem(index, dealerData) {
        let listItemTpl = this.templates.DEALERLISTITEM.clone()[0].outerHTML;
        const distance = dealerData.Distance > 0 ? `${dealerData.Distance.toFixed(1)}km` : '';
        listItemTpl = listItemTpl.replace(/%%INDEX%%/g, index);
        listItemTpl = listItemTpl.replace(/%%DEALERCODE%%/g, dealerData.DealerCode || 0);
        listItemTpl = listItemTpl.replace(/%%DISTANCE%%/g, distance);
        listItemTpl = listItemTpl.replace(/%%DEALERNAME%%/g, dealerData.DealerName || '');
        listItemTpl = listItemTpl.replace(/%%DEALERLOCATION%%/g, dealerData.City || '');
        return listItemTpl;
    }

    /**
     * Shows dealer details when clicked on dealer result based on current viewport
     * @param {jQueryEvent} e jQuery Event.
     * @param {data} [data] Optional data.
     */
    showDealerDetails(e) {
        // current dealeritem
        const $currentListItem = $(e.currentTarget);
        const dealerIndex = $currentListItem.data('index');
        const dealerCode = $currentListItem.data('code');

        // if the current flyout isnt opened yet
        if (this.openedDetailCode !== dealerCode) {
            this.openedDetailCode = dealerCode;

            this.$flyoutLoader.show();
            const $flyoutLoader = $currentListItem.find('.loader--flyout').show();

            // set active
            this.$dealerList.find('ul li').removeClass('is-active');
            $currentListItem.addClass('is-active');

            // show flyout based on viewport
            if (viewport.is('>=md')) {
                // desktop flyout
                this.showFlyout();

                // close event listener
                $('.flyout-container .icon--close')
                    .off('click.dealerlocator')
                    .on('click.dealerlocator', (e) => {
                        e.preventDefault();
                        this.hideFlyout();
                        this.$dealerList.find('ul li').removeClass('is-active');
                        this.openedDetailCode = false;

                        // return map to previous zoomlevel
                        this.map.setCenter(this.map.last.map.center, true);
                        this.map.setZoom(this.map.last.map.zoom, true);
                    });
            } else {
                // mobile flyout (collapse)
                const $dealerDetailCollapse = $currentListItem.find('.flyout-container.collapse');

                if ($dealerDetailCollapse.length > 0) {
                    $dealerDetailCollapse.collapse('show');
                }

                $dealerDetailCollapse.on('hidden.bs.collapse', () => {
                    $currentListItem.removeClass('is-active');
                    this.openedDetailCode = false;
                });

                // close event listener
                $dealerDetailCollapse
                    .find('.icon--close')
                    .off('click.dealerlocator')
                    .on('click.dealerlocator', (e) => {
                        e.preventDefault();
                        if ($dealerDetailCollapse.length > 0) {
                            $dealerDetailCollapse.collapse('hide');
                        }
                    });
            }

            // get all filter values to send them to the api
            // the api needs them to return a subset of dealertypes
            const dealerTypes = $('.dealertypes--search input.form-check-input');
            let dealerTypeValues = [];
            for (const type of dealerTypes) {
                dealerTypeValues.push($(type).val());
            }

            this.ajax
                .request({
                    url: `/${this.locale}/api/feature/dealer/getdealer`,
                    data: JSON.stringify({
                        dealerCode: dealerCode,
                        filters: dealerTypeValues.join(','),
                    }),
                })
                .then((response) => {
                    if (response.Success === true) {
                        // render result
                        this.renderDealerDetail(dealerIndex, response.Dealer).then((dealerDetail) => {
                            // replace current flyout
                            this.$flyoutContainers.find('.flyout').html(dealerDetail.html);
                            $currentListItem.find('.flyout').html(dealerDetail.html);

                            // center map on address
                            if (this.map.initialized) {
                                this.map.setCenter(
                                    { lat: dealerDetail.address.lat, lng: dealerDetail.address.lng },
                                    true
                                );
                                this.map.setZoom(10, true, false);
                            }

                            // update valtagmanager
                            if (window.digitalData) {
                                window.digitalData.update();
                            }

                            // hide loaders
                            this.$flyoutLoader.fadeOut(200);
                            $flyoutLoader.fadeOut(200);
                        });
                    } else {
                        // render error
                        console.log(response.Reason);
                    }
                });
        }
    }

    /**
     * Address, communications and openinghounrs logic
     * and renders the create data.
     * @param {number} index Index of current dealer.
     * @param {object} dealerData Object with dealer data.
     */
    renderDealerDetail(index, dealerData) {
        return new Promise((resolve) => {
            const $detailPanel = this.templates.DEALERDETAIL.clone();

            // define address data here; we need it in the main resolve.
            let addressData = {};

            // show services
            let businesses = [];
            if (typeof dealerData.Businesses !== 'undefined' && dealerData.Businesses.length > 0) {
                let dealerTypeTpl = this.templates.DEALERDETAILTYPES.clone()[0].outerHTML;
                dealerData.Businesses.forEach((business) => {
                    const dealerTypeHtml = dealerTypeTpl.replace(/%%DEALERTYPE%%/g, business.BusinessName);
                    businesses.push(dealerTypeHtml);
                });
            }
            if (businesses.length > 0) {
                $detailPanel.find('.dealertypes__container').html(businesses.join(''));
            }

            // render details
            let detailHtml = $detailPanel[0].outerHTML;
            detailHtml = detailHtml.replace(/%%DEALERNAME%%/g, dealerData.Name || '');

            // create address
            new Promise((resolve) => {
                let addresses = [];
                let promises = [];
                if (typeof dealerData.Addresses !== 'undefined' && dealerData.Addresses.length > 0) {
                    dealerData.Addresses.forEach((address) => {
                        const promise = new Promise((resolve) => {
                            if (address.AddressCode === 'LC') {
                                if (address.AddressLine1 !== '') {
                                    addresses.push(address.AddressLine1);
                                }
                                if (address.AddressLine2 !== '') {
                                    addresses.push(address.AddressLine2);
                                }
                                if (address.AddressLine3 !== '') {
                                    addresses.push(address.AddressLine3);
                                }
                                addressData.address = addresses.join('<br />');
                                addressData.postalcode = address.PostalCode;
                                addressData.city = address.City;
                                addressData.country = address.Country;
                                addressData.lat = address.Latitude;
                                addressData.lng = address.Longitude;
                                resolve();
                            } else {
                                resolve();
                            }
                        });
                        promises.push(promise);
                    });
                    Promise.all(promises).then(() => {
                        resolve(addressData);
                    });
                } else {
                    resolve();
                }
            }).then((address) => {
                // render addressdata
                detailHtml = detailHtml.replace(/%%DEALERADDRESS%%/g, address.address || '');
                detailHtml = detailHtml.replace(/%%DEALERPOSTALCODE%%/g, address.postalcode || '');
                detailHtml = detailHtml.replace(/%%DEALERCITY%%/g, address.city || '');
                detailHtml = detailHtml.replace(/%%DEALERCOUNTRY%%/g, address.country || '');

                // communications
                new Promise((resolve) => {
                    let promises = [];
                    let communicationData = {};
                    if (typeof dealerData.Communications !== 'undefined' && dealerData.Communications.length > 0) {
                        dealerData.Communications.forEach((comm) => {
                            const promise = new Promise((resolve) => {
                                if (comm.DepartmentCode === 'GEN') {
                                    communicationData.telephone = comm.Phone;
                                    communicationData.email = comm.Email;
                                    resolve();
                                } else {
                                    resolve();
                                }
                            });
                            promises.push(promise);
                        });
                        Promise.all(promises).then(() => {
                            resolve(communicationData);
                        });
                    } else {
                        resolve();
                    }
                }).then((commData) => {
                    // render communication
                    detailHtml = detailHtml.replace(/%%DEALERTELEPHONE%%/g, commData.telephone || '');
                    detailHtml = detailHtml.replace(/%%DEALEREMAILADDRESS%%/g, commData.email || '');
                    detailHtml = detailHtml.replace(/%%DEALERPAGEURL%%/g, dealerData.DealerPageUrl || '');

                    // render openinghours
                    detailHtml = detailHtml.replace(/%%DEALEROPENINGHOURS%%/g, dealerData.OpenTill || '');

                    // finally resolve
                    resolve({ html: detailHtml, address: addressData });
                });
            });
        });
    }

    /**
     * Shows desktop flyout and resizes map.
     */
    showFlyout() {
        this.$mainPanel.addClass('col-flyout');

        setTimeout(() => {
            if (this.map.initialized) {
                this.onResize();
            }
        }, 250);
    }

    /**
     * Hides desktop flyout and resizes map.
     */
    hideFlyout() {
        this.$mainPanel.removeClass('col-flyout');

        setTimeout(() => {
            if (this.map.initialized) {
                this.onResize();
            }
        }, 250);
    }

    /**
     * Shows results panel and hides search panel.
     */
    showResultsPanel() {
        this.$searchPanel.removeClass('is-active');
        this.$resultsPanel.addClass('is-active');
        this.hideFlyout();
    }

    /**
     * Shows search panel and hides results panel.
     */
    showSearchPanel() {
        this.$searchPanel.addClass('is-active');
        this.$resultsPanel.removeClass('is-active');
        this.hideFlyout();
    }

    /**
     * Updates country and shows country on the map.
     * @param {string} countryIso2 ISO 2 code of country.
     * @param {string} countryCode ISO 3 code of country.
     * @param {string} countryName Country name.
     * @param {boolean} [resetCity = true] Options boolean to reset city.
     * @param {boolean} [resetRange = true] Options boolean to reset range.
     */
    updateCountry(countryIso2, countryCode, countryName, resetCity = true, resetRange = true) {
        if (countryCode && countryName) {
            // create country object
            this.country = {
                name: countryName,
                iso2: countryIso2,
                iso3: countryCode,
            };

            // show loader if map is loaded
            if (this.map.initialized) {
                this.$mapLoader.show();
                if (this.markersGroup) {
                    this.markersGroup.removeAll();
                }
                if (this.clusterLayer) {
                    this.map.removeLayer(this.clusterLayer);
                }
            }

            this.map
                .getGeoLocation(countryCode)
                .then((response) => {
                    // set additional country data
                    this.country.center = { lat: response.lat, lng: response.lng };
                    this.country.topLeft = { lat: response.topLeft.lat, lng: response.topLeft.lng };
                    this.country.bottomRight = { lat: response.bottomRight.lat, lng: response.bottomRight.lng };

                    // update map
                    this.updateMap({
                        topLeft: {
                            lat: response.topLeft.lat,
                            lng: response.topLeft.lng,
                        },
                        bottomRight: {
                            lat: response.bottomRight.lat,
                            lng: response.bottomRight.lng,
                        },
                    });

                    // update zip code check when country changes
                    this.controls.ZIPCODE.prop('disabled', false);
                    if (this.zipCode) {
                        this.updateZipCode();
                    }

                    if (resetCity) {
                        // reset city
                        this.city = false;
                    }

                    if (resetRange) {
                        // reset range
                        this.resetRange();
                    } else {
                        // update range
                        //this.updateRange(this.range);
                    }

                    if (this.map.initialized) {
                        this.$mapLoader.fadeOut(200);
                    }
                })
                .catch(() => {
                    console.warn(`No geolocation found for ${countryName}.`);

                    if (resetRange) {
                        // reset range
                        this.resetRange();
                    } else {
                        // update range
                        this.updateRange(this.range);
                    }
                    if (this.map.initialized) {
                        this.$mapLoader.fadeOut(200);
                    }
                });
        } else {
            this.country = false;
            this.controls.ZIPCODE.prop('disabled', true);

            if (this.map.initialized) {
                if (this.markersGroup) {
                    this.markersGroup.removeAll();
                }
                if (this.clusterLayer) {
                    this.map.removeLayer(this.clusterLayer);
                }
            }
        }

        // show dealers on change of country
        setTimeout(() => {
            this.handleSearchButtonClick(null, false);
        }, 200);
    }

    /**
     * Updates city in a specific country and shows country on the map.
     * @param {string} city Name of city.
     */
    updateCity(city) {
        if (city) {
            // create city object
            this.city = {
                name: city,
            };

            // show loader if map is loaded
            if (this.map.initialized) {
                this.$mapLoader.show();
                // if (this.markersGroup) {
                //     this.markersGroup.removeAll();
                // }
                // if (this.clusterLayer) {
                //     this.map.removeLayer(this.clusterLayer);
                // }
            }

            // we want to search by city so reset zip code
            this.resetZipCode();

            this.map
                .getGeoLocation(this.country.iso3, city)
                .then((response) => {
                    // set additional city data
                    this.city.center = { lat: response.lat, lng: response.lng };
                    this.city.topLeft = { lat: response.topLeft.lat, lng: response.topLeft.lng };
                    this.city.bottomRight = { lat: response.bottomRight.lat, lng: response.bottomRight.lng };

                    // update map
                    this.updateMap({
                        topLeft: {
                            lat: response.topLeft.lat,
                            lng: response.topLeft.lng,
                        },
                        bottomRight: {
                            lat: response.bottomRight.lat,
                            lng: response.bottomRight.lng,
                        },
                    });

                    // update range
                    //this.updateRange(this.range);

                    if (this.map.initialized) {
                        this.$mapLoader.fadeOut(200);
                    }
                })
                .catch(() => {
                    console.warn(`No geolocation found for ${this.country.name}, ${city}.`);

                    if (this.map.initialized) {
                        this.$mapLoader.fadeOut(200);
                    }
                });
        } else {
            this.city = false;
            this.updateCountry(this.country.iso2, this.country.iso3, this.country.name, false, false);
        }
    }

    /**
     * Updates map.
     * @param {object} center Map center object.
     * @param {string} center.lat Map center latitude.
     * @param {string} center.lng Map center longitude.
     * @param {object} bbox Map boundingbox object
     * @param {object} bbox.topLeft.lat Map boundingbox topLeft latitude.
     * @param {object} bbox.topLeft.lng Map boundingbox topLeft longitude.
     * @param {object} bbox.bottomRight.lat Map boundingbox bottomRight latitude.
     * @param {object} bbox.bottomRight.lng Map boundingbox bottomRight longitude.
     * @param {number} [maxZoom = false] Optional max zoom level.
     */
    updateMap(bbox, maxZoom = false) {
        if (this.map.initialized) {
            // after changing bounding box, update map data
            $(this.map.map).one('mapviewchangeend', () => {
                this.updateMapData(maxZoom);
            });
            this.map.setBoundingBox(bbox.topLeft.lat, bbox.topLeft.lng, bbox.bottomRight.lat, bbox.bottomRight.lng);
        }
    }

    updateMapData(maxZoom = false) {
        this.map.setCenter(this.map.getCalculatedCenter());
        // update max zoom, if set
        if (maxZoom && this.map.getZoom() >= maxZoom) {
            this.map.setZoom(maxZoom);
        }
        this.updateRange(this.range);
    }

    /**
     * Handles changing of range.
     * @param {event} e jQuery Event.
     */
    handleChangeRange(e) {
        const range = $(e.currentTarget).val();
        if (range === '') {
            if (this.city) {
                this.updateCity(this.city.name);
            } else {
                this.updateCountry(this.country.iso2, this.country.iso3, this.country.name, true, true);
            }
            this.updateRange(0);
        } else {
            this.updateRange(range);
        }
    }

    /**
     * Updates range, rangeCircle and (re-)adds the circle to the map, if the range is set.
     * @param {number} range Range in km.
     */
    updateRange(range) {
        if (range) {
            this.range = range;
            if (this.map.initialized) {
                if (this.rangeCircle) {
                    this.map.removeObject(this.rangeCircle);
                    this.rangeCircle = false;
                }
                this.rangeCircle = this.map.createRange(range);
                this.map.addObject(this.rangeCircle);
                const bounds = this.rangeCircle.getBoundingBox();
                this.map.setBoundingBoxByRect(bounds);
            }
        } else {
            this.range = 0;
            if (this.map.initialized && this.rangeCircle) {
                this.map.removeObject(this.rangeCircle);
            }
        }
    }

    /**
     * Resets range to 0 and removes range circle.
     */
    resetRange() {
        this.range = 0;
        this.controls.RANGE.prop('selectedIndex', 0);
        this.updateRange(0);
    }

    /**
     * Handles use my location click event.
     * @param {event} jQuery Event.
     */
    handleUseLocation(e) {
        if (!$(e.currentTarget).parent().find('.form-check-input').prop('checked')) {
            // element will be checked, so fetch location
            this.getMyLocation()
                .then((position) => {
                    // set position
                    this.useLocation = position;

                    // disable controls
                    this.enableMainControls(false);
                    this.resetLatLng(false);

                    if (this.map.initialized) {
                        this.map.setCenter(position, true);
                        this.useLocationMarker = this.map.createMyLocationMarker(this.useLocation);
                        this.map.addObject(this.useLocationMarker);
                    }

                    // update range drawing
                    if (this.range) {
                        this.updateRange(this.range);
                    }
                })
                .catch((err) => {
                    console.warn(err);

                    if (this.map.initialized) {
                        this.map.removeObject(this.useLocationMarker);
                    }
                });
        } else {
            // element will be unchecked, remove location
            this.useLocation = false;

            if (this.map.initialized) {
                this.map.removeObject(this.useLocationMarker);

                // reset view to country/city/range selection
                this.updateCountry(this.country.iso2, this.country.iso3, this.country.name, false, false);
                this.updateCity(this.city.name);
                this.updateRange(this.range);
            }

            // (re-)enable controls
            this.enableMainControls(true);
        }
    }

    /**
     * Returns an object of current location based on navigation geolocation api.
     * @return {promise} Object containing lat/lng or error string.
     */
    getMyLocation() {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    resolve({ lat: position.coords.latitude, lng: position.coords.longitude });
                },
                () => {
                    reject('Unable to retrieve location.');
                }
            );
        });
    }

    updateZipCode() {
        const value = this.controls.ZIPCODE.val();

        if (!value) {
            this.hideFeedback(this.controls.ZIPCODE);
            return;
        }

        this.map
            .getGeoLocation(this.country.iso3, null, value)
            .then((response) => {
                this.setFeedback(this.controls.ZIPCODE, true);

                // set additional zip code data
                this.zipCode = {
                    value: value,
                    isValid: true,
                    center: { lat: response.lat, lng: response.lng },
                    topLeft: { lat: response.topLeft.lat, lng: response.topLeft.lng },
                    bottomRight: { lat: response.bottomRight.lat, lng: response.bottomRight.lng },
                };

                // update map
                this.updateMap({
                    topLeft: {
                        lat: response.topLeft.lat,
                        lng: response.topLeft.lng,
                    },
                    bottomRight: {
                        lat: response.bottomRight.lat,
                        lng: response.bottomRight.lng,
                    },
                });

                if (this.map.initialized) {
                    this.$mapLoader.fadeOut(200);
                }
            })
            .catch(() => {
                console.warn(`No geolocation found for ${this.country.name} ${value}`);

                this.setFeedback(this.controls.ZIPCODE, false);

                this.zipCode = { isValid: false };

                if (this.map.initialized) {
                    this.$mapLoader.fadeOut(200);
                }
            });
    }

    resetZipCode() {
        this.zipCode = null;
        this.controls.ZIPCODE.val('');
        this.hideFeedback(this.controls.ZIPCODE);
    }

    hideFeedback(control) {
        control.siblings('.valid-feedback').hide();
        control.siblings('.invalid-feedback').hide();
    }

    setFeedback(control, isValid) {
        if (isValid) {
            control.siblings('.valid-feedback').show();
            control.siblings('.invalid-feedback').hide();
        } else {
            control.siblings('.invalid-feedback').show();
            control.siblings('.valid-feedback').hide();
        }
    }

    handleGetLatLng(e) {
        e.preventDefault();

        if (this.latlngAreValid) {
            if (this.map.initialized) {
                const position = { lat: this.lat, lng: this.lng };
                this.map.setCenter(position, true);

                // if marker already exists, first remove that one
                if (this.latlngMarker) {
                    this.map.removeObject(this.latlngMarker);
                }

                this.latlngMarker = this.map.createMyLocationMarker(position);
                this.map.addObject(this.latlngMarker);
            }
        }
    }

    /**
     * Handle changing of lat/lng.
     * @param {event} e jQuery Event.
     */
    handleChangeLatLng(e) {
        const input = $(e.currentTarget);
        const name = input.attr('name');
        const value = input.val();

        this.lat = this.controls.LAT.val();
        this.lng = this.controls.LNG.val();

        // handle validation icons
        if (value) {
            if (name === 'latitude') {
                this.setFeedback(input, this.validateLat(value));
            } else if (name === 'longitude') {
                this.setFeedback(input, this.validateLng(value));
            }
        } else {
            this.hideFeedback(input);
        }

        this.latlngAreValid = this.validateLat(this.lat) && this.validateLng(this.lng);

        if (this.latlngAreValid) {
            this.enableMainControls(false);
            this.buttons.LATLNGSEARCHBTN.prop('disabled', false);
        } else {
            this.enableMainControls(true);
            this.buttons.LATLNGSEARCHBTN.prop('disabled', true);
        }
    }

    /**
     * validates latitude specifically
     * @param {string} lat
     */
    validateLat(lat) {
        const regex = /^-?([1-8]?[1-9]|[1-9]0)\.{1}\d{1,6}/;
        return regex.test(lat);
    }

    /**
     * validates longitude specifically
     * @param {string} lng
     */
    validateLng(lng) {
        const regex = /^-?([1]?[1-7][1-9]|[1]?[1-8][0]|[1-9]?[0-9])\.{1}\d{1,6}/;
        return regex.test(lng);
    }

    /**
     * enables/disables country/city and zipcode control fields
     * @param {boolean} enable
     */
    enableMainControls(enable) {
        if (enable) {
            this.cityCountry.enableControls();
            this.controls.ZIPCODE.prop('disabled', false);
        } else {
            this.cityCountry.disableControls();
            this.controls.ZIPCODE.prop('disabled', true);
        }
    }

    /**
     * @param {boolean} triggerChange if the change event of lat and lng fields should be triggered
     * this means state of  other controls will be updated based on the lat lng set
     */
    resetLatLng(triggerChange = true) {
        this.lat = 0;
        this.lng = 0;
        this.controls.LAT.val('');
        this.controls.LNG.val('');
        this.hideFeedback(this.controls.LAT);
        this.hideFeedback(this.controls.LNG);

        if (triggerChange) {
            this.controls.LAT.trigger('input');
            this.controls.LNG.trigger('input');
        }

        if (this.map.initialized) {
            this.map.removeObject(this.latlngMarker);
            this.latlngMarker = null;
        }
    }

    /**
     * Setup custom scrollbar (Perfect Scrollbar) for dealerlist.
     */
    initDealerScrollbar() {
        this.$dealerListScrollbar = new PerfectScrollbar(this.$dealerList[0], {
            wheelPropagation: true,
        });
    }

    /**
     * Shows/Hides map on resize events.
     */
    onResize() {
        if (viewport.is('>=md')) {
            // FireFox/Edge quick Fix: map container needs to have a height (which isnt 100%)
            this.$mapElement.height(this.$element.height());

            if (!this.map.initialized) {
                this.$mapElement.fadeIn(200, () => {
                    this.map.init();
                    this.handleSearchButtonClick(null, false);
                });
            } else {
                this.map.onResize();
            }

            if (!this.$dealerListScrollbar) {
                this.initDealerScrollbar();
            }
        } else {
            if (this.$dealerListScrollbar) {
                this.$dealerListScrollbar.destroy();
                this.$dealerListScrollbar = null;
            }
            this.hideFlyout();
        }
    }

    /**
     * Returns marker icon svg markup.
     * @returns {string} SVG of marker icon.
     */
    getMarkerSvgMarkup() {
        return [
            /* eslint-disable */
            '<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="64" height="64" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">',
            '<g>',
            '<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="32" y1="16.5" x2="32" y2="47.5">',
            '<stop  offset="0" style="stop-color:#407DB5"/>',
            '<stop  offset="0.3828" style="stop-color:#2168A9"/>',
            '<stop  offset="0.7653" style="stop-color:#09589F"/>',
            '<stop  offset="1" style="stop-color:#00529C"/>',
            '</linearGradient>',
            '<path style="fill:url(#SVGID_1_);" d="M32,16.5c-6.9,0-12.5,5.8-12.5,13c0,3.7,1.5,7,3.9,9.4l8.6,8.6l8.6-8.6c2.4-2.4,3.9-5.7,3.9-9.4C44.5,22.3,38.9,16.5,32,16.5z"/>',
            '</g>',
            '<path style="opacity:0.2;fill:#353C45;" d="M54,38c-4.1,0-9.1,0-13.5,0.9L32,47.4l15.4-2.9c4-0.7,7.9-1.7,10.4-2.7C62.7,39.7,61,38,54,38z"/>',
            '<text x="32" y="34" font-size="10pt" font-family="Arial" font-weight="bold" text-anchor="middle" fill="#FFFFFF">{{LABEL}}</text>',
            '</svg>',
            /* eslint-enable */
        ].join();
    }

    /**
     * Returns cluster icon svg markup.
     * @returns {string} SVG of cluster icon.
     */
    getClusterSvgMarkup() {
        return [
            /* eslint-disable */
            '<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="64" height="64" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">',
            '<path style="opacity:0.2;fill-rule:evenodd;clip-rule:evenodd;fill:#353C45;" d="M64,32.3c0,0.1,0,0.2,0,0.3h0C63.6,50,49.5,64,32,64C14.5,64,0.4,50,0,32.6h0c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3h0C0.4,14.3,14.5,0,32,0s31.6,14.3,32,32h0C64,32.1,64,32.2,64,32.3z"/>',
            '<path style="fill-rule:evenodd;clip-rule:evenodd;fill:#353C45;" d="M54,32.2c0,0.1,0,0.1,0,0.2h0C53.8,44.4,44,54,32,54c-12,0-21.8-9.6-22-21.6h0c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2h0c0.2-12.2,10-22,22-22s21.8,9.8,22,22h0C54,32.1,54,32.1,54,32.2z"/>',
            '<g>',
            '<polygon style="fill:#FFFFFF;" points="39,31 33,31 33,25 31,25 31,31 25,31 25,33 31,33 31,39 33,39 33,33 39,33"/>',
            '</g>',
            '</svg>',
            /* eslint-enable */
        ].join();
    }

    /**
     * Returns my location icon svg markup.
     * @returns {string} SVG of my location icon.
     */
    getMyLocationSvgMarkup() {
        return [
            /* eslint-disable */
            '<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="32" height="32" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">',
            '<path style="fill:#fc5f01" d="M63.2,4.9L34.8,61.7c-0.5,1-1.3,1.6-2.5,1.6c-0.1,0-0.4,0-0.7-0.1c-0.7-0.1-1.2-0.5-1.6-1c-0.4-0.5-0.6-1.1-0.6-1.8V34.8H3.9c-0.7,0-1.2-0.2-1.8-0.6c-0.5-0.4-0.9-0.9-1-1.6C1,32,1,31.4,1.3,30.8c0.3-0.6,0.7-1,1.3-1.3L59.4,1.1c0.4-0.2,0.8-0.3,1.3-0.3c0.8,0,1.5,0.3,2,0.8c0.4,0.4,0.7,0.9,0.8,1.5S63.5,4.3,63.2,4.9z"/>',
            '</svg>',
            /* eslint-enable */
        ].join();
    }
}

// register to Component Handler
window.ComponentHandler.register({
    constructor: DealerLocator,
    classAsString: 'DealerLocator',
    cssClass: 'js-dealerlocator',
});

export default DealerLocator;
